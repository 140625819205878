import React, { useState } from "react";
import { Link } from "gatsby";
import Sidebar from "./Sidebar";
import PostSorter from "./PostSorter";
import ArchivePickerYear from "./ArchivePickerYear";
import SingleRowVertical from "./post-previews/SingleRowVertical";
import {
  PageTitle,
  PageDivider,
  MaxWidth,
  FlexRow,
  Col66,
  Col33,
  SmallDivider,
  SmallText,
  PickerSorterWrapper,
} from "./ui-components";

export default function ArchiveYear({ oldestPosts, newestPosts, year, sidebarInfo, postsMonths }) {
  const [sortByNewest, setSortByNewest] = useState(true);

  const handleOnChange = (sortByNewest) => {
    setSortByNewest(sortByNewest);
  };

  const { allSanitySiteSettings, allSanityAuthor, tags } = sidebarInfo;

  const NewestPosts = (
    <SingleRowVertical
      posts={newestPosts}
      authorTop={true}
      readMoreLink={true}
      className={"border-bottom"}
    />
  );

  const OldestPosts = (
    <SingleRowVertical
      posts={oldestPosts}
      authorTop={true}
      readMoreLink={true}
      className={"border-bottom"}
    />
  );

  <PostSorter sortByNewest={sortByNewest} handleOnChange={handleOnChange} />;

  return (
    <div>
      <MaxWidth>
        <PageTitle>{year} Archive</PageTitle>
      </MaxWidth>
      <PageDivider />
      <MaxWidth>
        <FlexRow>
          <Col66>
            <p style={{ marginBottom: 0, color: "0,0,0,0.84" }}>
              Top stories published by Inside Cal Poly in {year}.
            </p>

            <SmallDivider />

            <PickerSorterWrapper>
              <ArchivePickerYear postsYear={year} postsMonths={postsMonths} />
              <PostSorter sortByNewest={sortByNewest} handleOnChange={handleOnChange} />
            </PickerSorterWrapper>

            <SmallDivider />

            {sortByNewest ? NewestPosts : OldestPosts}

            <SmallText>
              These were the top stories published by Inside Cal Poly in {year}; you can also browse
              other articles written by Inside Cal Poly by viewing the full{" "}
              <Link to={"/archive/"}>archive</Link>.
            </SmallText>
          </Col66>

          <Col33>
            <Sidebar
              settings={allSanitySiteSettings.nodes[0]}
              tags={tags}
              authors={allSanityAuthor.nodes}
            />
          </Col33>
        </FlexRow>
      </MaxWidth>
    </div>
  );
}
