import React from "react";
import { SmallText } from "./ui-components";
import { Link } from "gatsby";
import styled from "styled-components";

const OuterWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 449px) {
    flex-direction: column;
  }
`;

const YearsList = styled.ul`
  list-style: none;
  margin: 0;

  li,
  p {
    margin: 0;
    display: inline;
  }

  li {
    margin-right: 0.75rem;
    position: relative;

    &.arrow {
      margin-right: 1.5rem;

      &:after {
        content: "";
        position: absolute;
        border: solid rgb(3, 86, 66);
        border-width: 0 1px 1px 0;
        padding: 3px;

        top: 50%;
        right: -0.75rem;

        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }

  @media only screen and (max-width: 449px) {
    margin-bottom: 0.6rem;
  }
`;

const MonthsList = styled.ul`
  list-style: none;
  margin: 0;

  li,
  p {
    margin: 0;
    display: inline;
  }

  display: grid;
  justify-items: start;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.25rem;
  max-width: 15rem;
`;

const StyledSmallText = styled(SmallText)`
  color: rgba(100, 100, 100, 0.8);
`;

const genericMonths = [
  { monthInt: "01", monthString: "January" },
  { monthInt: "02", monthString: "February" },
  { monthInt: "03", monthString: "March" },
  { monthInt: "04", monthString: "April" },
  { monthInt: "05", monthString: "May" },
  { monthInt: "06", monthString: "June" },
  { monthInt: "07", monthString: "July" },
  { monthInt: "08", monthString: "August" },
  { monthInt: "09", monthString: "September" },
  { monthInt: "10", monthString: "October" },
  { monthInt: "11", monthString: "November" },
  { monthInt: "12", monthString: "December" },
];

export default function ArchivePickerYear({ postsYear, postsMonths }) {
  const monthsLinks = genericMonths.map((month) => {
    const { monthInt, monthString } = month;

    let MonthItem = <StyledSmallText>{monthString}</StyledSmallText>;

    postsMonths.forEach((postsMonth) => {
      if (postsMonth.monthInt === month.monthInt) {
        MonthItem = (
          <SmallText>
            <Link to={`/archive/${postsYear}/${monthInt}/`}>{monthString}</Link>
          </SmallText>
        );
      }
    });

    return <li key={month.monthInt}>{MonthItem}</li>;
  });

  return (
    <OuterWrapper>
      <YearsList>
        <li key={"all"} className={"arrow"}>
          <SmallText>
            <Link to="/archive/">All</Link>
          </SmallText>
        </li>
        <li key={"year"} className={"arrow"}>
          <SmallText>
            <Link to={`/archive/${postsYear}/`}>{postsYear}</Link>
          </SmallText>
        </li>
      </YearsList>
      <MonthsList>{monthsLinks}</MonthsList>
    </OuterWrapper>
  );
}
