import React from "react";
import { graphql } from "gatsby";
import ArchiveYear from "../components/ArchiveYear";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterUniqueTags } from "../lib/helpers";

export const query = graphql`
  query ArchiveYearTemplateQuery($year: Date!, $nextYear: Date!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    oldestPosts: allSanityPost(
      sort: { fields: [publishedAt], order: ASC }
      filter: { publishedAt: { gte: $year, lt: $nextYear, ne: null } }
      limit: 10
    ) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMM DD, YYYY")
          slug {
            current
          }
          mainImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          authors {
            author {
              name
              id
              image {
                asset {
                  fixed(width: 36) {
                    ...GatsbySanityImageFixed_noBase64
                  }
                }
              }
              slug {
                current
              }
            }
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          tags {
            tagName
            slug {
              current
            }
          }
        }
      }
    }
    newestPosts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { publishedAt: { gte: $year, lt: $nextYear, ne: null } }
      limit: 10
    ) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMM DD, YYYY")
          slug {
            current
          }
          mainImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          authors {
            author {
              name
              id
              image {
                asset {
                  fixed(width: 36) {
                    ...GatsbySanityImageFixed_noBase64
                  }
                }
              }
              slug {
                current
              }
            }
          }
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          tags {
            tagName
            slug {
              current
            }
          }
        }
      }
    }
    postsMonths: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { publishedAt: { gte: $year, lt: $nextYear, ne: null } }
    ) {
      edges {
        node {
          monthInt: publishedAt(formatString: "MM")
        }
      }
    }
    allSanitySiteSettings {
      nodes {
        contactInfo {
          email
          facebook
          instagram
          twitter
        }
        description
        title
      }
    }
    allSanityAuthor {
      nodes {
        image {
          asset {
            fixed(width: 40) {
              ...GatsbySanityImageFixed
            }
          }
        }
        id
        name
        slug {
          current
        }
      }
    }
  }
`;

const ArchiveYearTemplate = (props) => {
  const { data, location } = props;

  const oldestPosts = data && data.oldestPosts && mapEdgesToNodes(data.oldestPosts);
  const newestPosts = data && data.newestPosts && mapEdgesToNodes(data.newestPosts);
  const year = oldestPosts && oldestPosts[0].publishedAt.slice(-4);

  const allSanitySiteSettings = data && data.allSanitySiteSettings;
  const allSanityAuthor = data && data.allSanityAuthor;

  const allPosts = [...oldestPosts, ...newestPosts];
  const tags = filterUniqueTags(allPosts);

  const sidebarInfo = { allSanitySiteSettings, allSanityAuthor, tags };

  const postsMonths = data && data.newestPosts && mapEdgesToNodes(data.postsMonths);

  return (
    <Layout location={location.pathname}>
      <SEO
        title={`${year} Archive` || "Untitled"}
        description={`Archive of stories published by Inside Cal Poly in ${year}.`}
      />
      <ArchiveYear
        oldestPosts={oldestPosts}
        newestPosts={newestPosts}
        year={year}
        sidebarInfo={sidebarInfo}
        postsMonths={postsMonths}
      />
    </Layout>
  );
};

export default ArchiveYearTemplate;
